@import '../../../../scss/theme-bootstrap';

.split-width-image-text-tout {
  @include container-padding-rules;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  &.column-layout {
    &-standard {
      .split-width-image-text-tout {
        &__column1 {
          @media #{$cr19-large-up} {
            order: 1;
            padding-#{$rdirection}: 1em;
          }
        }
        &__column2 {
          padding: 12px 0 0;
          @media #{$cr19-xsmall-up} {
            @include swap_direction(padding, 12px 28px 0 28px);
          }
          @media #{$cr19-small-up} {
            @include swap_direction(padding, 12px 36px 0 36px);
          }
          @media #{$cr19-large-up} {
            @include swap_direction(padding, 0 0 0 1em);
            order: 2;
          }
        }
      }
    }
    &-reversed {
      .split-width-image-text-tout {
        &__column1 {
          @media #{$cr19-large-up} {
            order: 2;
            padding-#{$ldirection}: 1em;
          }
        }
        &__column2 {
          @include swap_direction(padding, 12px 20px 0 20px);
          @media #{$cr19-xsmall-up} {
            @include swap_direction(padding, 12px 28px 0 28px);
          }
          @media #{$cr19-small-up} {
            @include swap_direction(padding, 12px 36px 0 36px);
          }
          @media #{$cr19-large-up} {
            @include swap_direction(padding, 0 1em 0 0);
            order: 1;
          }
        }
      }
    }
  }
  &.row-layout-reversed {
    flex-direction: column-reverse;
    @media #{$cr19-large-up} {
      flex-direction: row;
    }
  }
  &__column1,
  &__column2 {
    width: 100%;
    @media #{$cr19-large-up} {
      width: 50%;
    }
  }
  &__cta {
    margin-top: 1em;
  }
  // Typography defaults when no styles are selected in cms
  &__title {
    .style--bare {
      @include text-title--large;
    }
  }
  &__subtitle {
    .style--bare {
      @include text-title--med;
    }
  }
  &__body {
    .style--bare {
      @include text-body-text;
    }
  }
  &__full-width {
    @include container-max-width-x-large;
  }
}
